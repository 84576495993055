import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import {
  Alert,
  Box,
  CircularProgress,
  styled,
  Typography,
} from '@mui/material';
import { useConfirmEmail } from 'api/evaluator.hooks';
import {
  TextInputV2,
  ButtonV2,
  TextV2,
} from '@insights-ltd/design-library/components';
import { Dialects } from 'types/dialects';
import { RequestError } from 'api/evaluator';
import { VALID_EMAIL_REGEX } from 'variables';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';

type FormData = {
  emailAddress: string;
};

const StyledEmailFormContainerDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  minHeight: '70vh',
  marginTop: theme.spacing(spacingSizeMap.S),
  paddingRight: theme.spacing(1.75 * spacingSizeMap.L),
  paddingLeft: theme.spacing(1.5 * spacingSizeMap.L),
}));

const StyledTopRowDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: theme.spacing(spacingSizeMap.L),
}));

const StyledForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(spacingSizeMap.S),
}));

const StyledButtonWrapperDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  [theme.breakpoints.up(769)]: {
    justifyContent: 'flex-end',
  },
}));

const Error = ({ show, message }: { show: boolean; message: string }) =>
  show ? (
    <Box my={1}>
      <Alert severity="error" variant="filled">
        {message}
      </Alert>
    </Box>
  ) : null;

const EmailForm = ({
  defaultEmailAddress,
  onComplete,
  evaluatorLinkId,
}: {
  defaultEmailAddress: string;
  onComplete: (emailAddress: string) => void;
  evaluatorLinkId: string;
}) => {
  const { t, i18n } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: { emailAddress: defaultEmailAddress },
    shouldUseNativeValidation: true,
  });

  const { mutate: confirmEmail, isPending, isError, error } = useConfirmEmail();
  const requestError = error as RequestError;
  const isLinkError = requestError?.status === 404;
  const isEmailError = requestError?.errorCodes?.includes(
    'INVALID_EMAIL_ADDRESS',
  );
  const isGenericError = isError && !isLinkError && !isEmailError;

  const { ref: emailAddressRef, ...emailAddressProps } = {
    ...register('emailAddress', {
      required: true,
      pattern: VALID_EMAIL_REGEX,
    }),
  };

  return (
    <StyledEmailFormContainerDiv>
      <StyledTopRowDiv>
        <TextV2 variant="h2">
          {t('ui.io.confirm_email.email_form.heading', {})}
        </TextV2>

        <Error
          show={isGenericError}
          message={t('ui.io.error.something_wrong')}
        />
        <Error
          show={isLinkError}
          message={t('ui.io.confirm_email.error.invalid_evaluator_link')}
        />
        <Error
          show={isEmailError}
          message={t('ui.io.confirm_email.error.email_send_fail')}
        />

        <Typography
          sx={(theme) => ({
            marginTop: theme.spacing(2),
            lineHeight: '1.75rem',
          })}
          color="textPrimary"
        >
          {t('ui.io.confirm_email.email_form.why_detail', {})}
        </Typography>

        <StyledForm
          onSubmit={handleSubmit(({ emailAddress }: FormData) => {
            confirmEmail(
              {
                data: { emailAddress, dialect: i18n.language as Dialects },
                evaluatorLinkId,
              },
              { onSuccess: () => onComplete(emailAddress) },
            );
          })}
          noValidate
        >
          <TextInputV2
            sx={(theme) => ({
              marginTop: theme.spacing(0.5),
              marginBottom: theme.spacing(5),
              '& .MuiOutlinedInput-root': {
                width: '100%',
                '& input': {
                  width: '100%',
                },
              },
            })}
            data-testid="email-input"
            id="emailAddress"
            labelText={t('ui.io.confirm_email.email_form.input_label')}
            placeholder={t(
              'ui.io.confirm_email.email_form.input_default_placeholder',
            )}
            type="email"
            variant="outlined"
            error={!!errors.emailAddress}
            helperText={
              errors.emailAddress &&
              t('ui.io.confirm_email.email_form.error.invalid_email')
            }
            disabled={isPending}
            innerRef={emailAddressRef}
            {...emailAddressProps}
          />
          <StyledButtonWrapperDiv>
            <ButtonV2
              disableRipple
              buttonText={t('ui.io.confirm_email.email_form.confirm_email', {})}
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              disabled={isPending}
              endIcon={isPending && <CircularProgress size={16} />}
            />
          </StyledButtonWrapperDiv>
        </StyledForm>
      </StyledTopRowDiv>
    </StyledEmailFormContainerDiv>
  );
};

export default EmailForm;
