import React, { useState } from 'react';
import {
  ButtonV2,
  H2,
  Icon,
  SvgAlertDiamondBold,
  SvgEmailActionBold,
  TextInputV2,
  TextV2,
} from '@insights-ltd/design-library/components';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import { Box, styled } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useGetMagicLinkEmail, useReissueMagicLink } from 'api/profile.hooks';
import { FullScreenError, FullScreenSpinner } from 'components';
import useAboveMobile from 'components/hooks/useAboveMobile';

const StyledH2 = styled(H2)(({ theme }) => ({
  margin: `${theme.spacing(spacingSizeMap.S)} 0`,
}));

type LinkReissueForm = {
  emailAddress: string;
};

type ExploreReissueProps = {
  apiToken: string | undefined;
  locale: any;
};

const ExploreReissue = ({ apiToken, locale }: ExploreReissueProps) => {
  const aboveMobile = useAboveMobile();
  const { t } = useTranslation();
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<LinkReissueForm>();
  const [emailSent, setEmailSent] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { status, data } = useGetMagicLinkEmail(apiToken!);
  const { mutate } = useReissueMagicLink();

  const onSubmitHandler = () => {
    mutate(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      { apiToken: apiToken!, locale },
      {
        onSuccess: () => {
          setEmailSent(true);
        },
      },
    );
  };

  if (status === 'pending') {
    return <FullScreenSpinner />;
  }
  if (status === 'error') {
    return (
      <FullScreenError
        message={t('ui.io.explore.evaluator.digital_profile.error')}
      />
    );
  }

  return (
    <Box
      sx={(theme) => ({
        width: aboveMobile ? '66.6%' : '100%',
        paddingBottom: theme.spacing(spacingSizeMap.XL),
      })}
    >
      {!emailSent ? (
        <>
          <Icon icon={<SvgAlertDiamondBold />} iconSize="large" />
          <StyledH2>{t('ui.io.explore.send-link.title')}</StyledH2>
          <TextV2 component="p">
            {t('ui.io.explore.send-link.description')}
          </TextV2>
          <Box
            component="form"
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              gap: theme.spacing(spacingSizeMap.S),
              marginTop: theme.spacing(spacingSizeMap.M),
              '> *:first-child': {
                flexGrow: '1',
              },
            })}
            onSubmit={handleSubmit(onSubmitHandler)}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: aboveMobile ? 'row' : 'column',
              }}
            >
              <Box sx={{ flex: 1 }}>
                <TextInputV2
                  id="emailAddress"
                  labelText={t('ui.io.explore.send-link.label')}
                  placeholder={t('ui.io.explore.send-link.placeholder')}
                  fullWidth
                  sx={{ '& .MuiInputBase-input': { width: '100%' } }}
                  error={Boolean(errors.emailAddress)}
                  helperText={
                    errors.emailAddress &&
                    t('ui.io.explore.send-link.error.required')
                  }
                  {...register('emailAddress')}
                  value={data.emailAddress}
                  disabled
                />
              </Box>
              <ButtonV2
                sx={(theme) => ({
                  marginTop: aboveMobile
                    ? theme.spacing(spacingSizeMap.L)
                    : theme.spacing(spacingSizeMap.XS),
                  marginLeft: aboveMobile
                    ? theme.spacing(spacingSizeMap.XS)
                    : 0,
                  flexShrink: 0,
                  paddingRight: '16px',
                })}
                buttonText={`${t('ui.io.explore.send-link.button')}`}
                type="submit"
              />
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Icon
            icon={<SvgEmailActionBold />}
            iconColour="blue"
            iconSize="large"
          />
          <StyledH2>{t('ui.io.explore.link-sent.title')}</StyledH2>
          <TextV2 component="p">
            {t('ui.io.explore.link-sent.description', {
              emailAddress: data.emailAddress,
            })}
          </TextV2>
        </>
      )}
    </Box>
  );
};

export default ExploreReissue;
