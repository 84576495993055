import React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { Box, MenuItem, Typography } from '@mui/material';
import { SubtextTextField } from 'components/SubtextTextField';
import { PRONOUNS_INCLUSIVE } from 'variables';
import { setFocusOnFirstError } from 'utils/formHelpers';
import { TextV2 } from '@insights-ltd/design-library/components';
import FormNavigation from './FormNavigation';
import { StepDFCData } from './types';
import { Select } from './FormInputs';
import FormLayoutDFC from './FormLayoutDFC';
import { StyledPronounInfoWrapper } from './StyledComponents';

const StepDFC = ({
  onNext,
  userInfo,
}: {
  onNext: (data: FieldValues) => void;
  userInfo: StepDFCData;
}) => {
  const { t } = useTranslation();
  const methods = useForm<FieldValues>({
    defaultValues: userInfo,
  });

  const textFields = [
    {
      id: 'full-name',
      name: 'fullName',
      label: t('ui.io.user_info.full_name.input_description'),
      errorMessage: t('ui.io.user_info.full_name.input_description.error'),
      optional: false,
      dataTestId: 'full-name-dfc-field',
    },
    {
      id: 'known-as',
      name: 'knownAs',
      label: t('ui.io.user_info.known_as.input_description'),
      subtext: t('ui.io.user_info.known_as.input_tooltip'),
      errorMessage: t('ui.io.user_info.known_as.input_description.error'),
      optional: false,
      dataTestId: 'known-as-dfc-field',
    },
  ];

  return (
    <FormLayoutDFC>
      <form
        onSubmit={methods.handleSubmit(onNext, (submitErrors) => {
          setFocusOnFirstError(submitErrors, methods.setFocus);
        })}
        noValidate
      >
        <Box mb={2}>
          <Typography color="textSecondary">
            {t('ui.io.onboarding.dfc.finish_creating_account')}
          </Typography>
        </Box>
        <div>
          <FormProvider {...methods}>
            {textFields.map((field) => (
              <SubtextTextField
                key={field.id}
                id={field.id}
                label={field.label}
                name={field.name}
                register={methods.register}
                dataTestId={field.dataTestId}
                errorMessage={field.errorMessage}
              />
            ))}
            <Select
              id="pronoun"
              name="pronoun"
              label={t('ui.io.pronouns.input_description')}
              control={methods.control}
              error={Boolean(methods.formState.errors.pronoun)}
              errorMessage={t('ui.io.pronouns.input_description.error')}
              autoComplete="sex"
            >
              {PRONOUNS_INCLUSIVE.map(({ textKey, value }) => (
                <MenuItem disableRipple key={value} value={value}>
                  {t(textKey)}
                </MenuItem>
              ))}
            </Select>
            <StyledPronounInfoWrapper>
              <TextV2 component="p" color="textPrimary" variant="bodyBold">
                {t('ui.io.onboarding.dfc.details_info.title')}
              </TextV2>
              <TextV2 component="p" color="textSecondary">
                {t('ui.io.onboarding.dfc.details_info.body')}
              </TextV2>
            </StyledPronounInfoWrapper>
          </FormProvider>
        </div>
        <FormNavigation />
      </form>
    </FormLayoutDFC>
  );
};

export default StepDFC;
