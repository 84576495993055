import React, { useEffect, useState, useMemo } from 'react';
import {
  JumpTo,
  SvgArrowLeft1Regular,
  TextV2,
  WistiaVideoPlayerButton,
} from '@insights-ltd/design-library/components';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import { Box, Grid, Typography } from '@mui/material';
import { ProfileLayout, FullScreenError, FullScreenSpinner } from 'components'; // Import FullScreenSpinner
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { useGetProfileScores } from 'api/profile.hooks';
import { RequestError } from 'api/RequestError';
import { Dialects } from 'types/dialects';
import {
  EVALUATOR_TITLE_KEYS,
  PAGE_TITLE_KEYS,
  getCaptionLanguageCode,
} from 'variables';
import { ThemeType } from '../Explore/ExploreProfile';
import learnImg from '../assets/themes-learn.jpg';
import remoteWorkingImg from '../assets/themes-remote-working.jpg';
import serviceImg from '../assets/themes-service.jpg';
import wellbeingImg from '../assets/themes-wellbeing.jpg';
import Skills from './Skills';
import ExploreReissue from '../Explore/ExploreReissue';

const ThemeData = [
  { id: 'learn', videoId: 'j0p9aoxlq4' },
  { id: 'remote-working', videoId: '7vutofm2o6' },
  { id: 'service', videoId: 'qrovxd2srb' },
  { id: 'wellbeing', videoId: 'i4gzm0ye0d' },
];

type UrlParametersDigitalProfile = {
  profileId: string;
  apiToken: string;
};

const ExploreTheme = () => {
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation();
  const [captionLanguage, setCaptionsLanguage] = useState(
    getCaptionLanguageCode(i18n.language, 'PREFERRED_DIALECTS'),
  );
  const [searchParams] = useSearchParams();
  const dialect: Dialects = searchParams.get('dialect') as Dialects;
  const { profileId, apiToken } = useParams<UrlParametersDigitalProfile>();
  const { language: locale } = i18n;

  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const exploreTheme = /[^/]*$/.exec(pathname)![0];
  const exploreThemeIndex = ThemeData.findIndex(
    (theme) => theme.id === exploreTheme,
  );
  const themeDataExists = exploreThemeIndex >= 0;
  const exploreThemeKey = exploreTheme.replace('-', '') as ThemeType;

  const {
    data: profile,
    status,
    error,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  } = useGetProfileScores(profileId!, apiToken!);

  useMemo(() => {
    if (i18n.language !== captionLanguage) {
      setCaptionsLanguage(
        getCaptionLanguageCode(i18n.language, 'PREFERRED_DIALECTS'),
      );
    }
  }, [i18n.language, captionLanguage]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on component mount
    i18n.changeLanguage(dialect);
  }, [dialect, i18n]);

  const jumpToPages: Record<ThemeType, { label: string; anchorId: string }[]> =
    {
      learn: [
        {
          label: t('ui.io.explore.themes.jumpnav-learn-study'),
          anchorId: 'study',
        },
        {
          label: t('ui.io.explore.themes.jumpnav-learn-creativity'),
          anchorId: 'creativity',
        },
        {
          label: t('ui.io.explore.themes.jumpnav-learn-learning-shots'),
          anchorId: 'learningshots',
        },
        {
          label: t('ui.io.explore.themes.jumpnav-learn-mindset'),
          anchorId: 'mindset',
        },
        {
          label: t('ui.io.explore.themes.jumpnav-learn-stress'),
          anchorId: 'stress',
        },
        {
          label: t('ui.io.explore.themes.jumpnav-learn-critical-thinking'),
          anchorId: 'criticalthinking',
        },
      ],
      remoteworking: [
        {
          label: t('ui.io.explore.themes.jumpnav-remote-working-adaptability'),
          anchorId: 'adaptability',
        },
        {
          label: t('ui.io.explore.themes.jumpnav-remote-working-belonging'),
          anchorId: 'belonging',
        },
        {
          label: t('ui.io.explore.themes.jumpnav-remote-working-motivation'),
          anchorId: 'motivation',
        },
        {
          label: t('ui.io.explore.themes.jumpnav-remote-working-productivity'),
          anchorId: 'productivity',
        },
        {
          label: t('ui.io.explore.themes.jumpnav-remote-working-influencing'),
          anchorId: 'influencing',
        },
        {
          label: t('ui.io.explore.themes.jumpnav-remote-working-meeting-tips'),
          anchorId: 'meetingtips',
        },
      ],
      service: [
        {
          label: t(
            'ui.io.explore.themes.jumpnav-service-relationship-building',
          ),
          anchorId: 'relationshipbuilding',
        },
        {
          label: t('ui.io.explore.themes.jumpnav-service-communication'),
          anchorId: 'communication',
        },
        {
          label: t('ui.io.explore.themes.jumpnav-service-being-you'),
          anchorId: 'beingyou',
        },
        {
          label: t('ui.io.explore.themes.jumpnav-service-adaptability'),
          anchorId: 'adaptability',
        },
        {
          label: t('ui.io.explore.themes.jumpnav-service-customer-challenges'),
          anchorId: 'customerchallenges',
        },
        {
          label: t('ui.io.explore.themes.jumpnav-service-finding-solutions'),
          anchorId: 'findingsolutions',
        },
      ],
      wellbeing: [
        {
          label: t('ui.io.explore.themes.jumpnav-wellbeing-positive-mindset'),
          anchorId: 'positivemindset',
        },
        {
          label: t('ui.io.explore.themes.jumpnav-wellbeing-managing-stress'),
          anchorId: 'managingstress',
        },
        {
          label: t('ui.io.explore.themes.jumpnav-wellbeing-self-care'),
          anchorId: 'selfcare',
        },
        {
          label: t('ui.io.explore.themes.jumpnav-wellbeing-unplugging'),
          anchorId: 'unplugging',
        },
        {
          label: t('ui.io.explore.themes.jumpnav-wellbeing-self-esteem'),
          anchorId: 'selfesteem',
        },
        {
          label: t('ui.io.explore.themes.jumpnav-wellbeing-handling-emotions'),
          anchorId: 'handlingemotions',
        },
      ],
    };

  let heroImg = learnImg;
  if (exploreTheme === 'remote-working') {
    heroImg = remoteWorkingImg;
  }
  if (exploreTheme === 'service') {
    heroImg = serviceImg;
  }
  if (exploreTheme === 'wellbeing') {
    heroImg = wellbeingImg;
  }

  let anchorId1;
  let anchorId2;
  let anchorId3;
  let anchorId4;
  let anchorId5;
  let anchorId6;
  if (jumpToPages[exploreThemeKey]) {
    [anchorId1, anchorId2, anchorId3, anchorId4, anchorId5, anchorId6] =
      jumpToPages[exploreThemeKey];
  }

  const requestError = error as RequestError;
  const isProfileTokenExpiredOrUsedError = requestError?.errorCodes?.includes(
    'INVITE_EXPIRED_OR_USED',
  );
  const isProfileNotFoundError =
    requestError?.errorCodes?.includes('NOT_FOUND');

  if (status === 'pending') {
    return <FullScreenSpinner />;
  }
  if (status === 'error' && isProfileNotFoundError) {
    return (
      <FullScreenError
        message={t('ui.io.explore.evaluator.digital_profile.error')}
      />
    );
  }
  if (status === 'error' && isProfileTokenExpiredOrUsedError) {
    return (
      <ProfileLayout title={t('ui.io.explore.evaluator.digital_profile.title')}>
        <Helmet
          title={`${t(PAGE_TITLE_KEYS.DIGITAL_PROFILE)} - ${t(
            EVALUATOR_TITLE_KEYS.EXPLORE,
          )}`}
        />
        <ExploreReissue apiToken={apiToken} locale={locale} />
      </ProfileLayout>
    );
  }

  if (status === 'success') {
    return (
      <ProfileLayout
        title={t('ui.io.explore.themes.header-title')}
        hero={{
          image: heroImg,
          title: t(`ui.io.explore.themes.title-${exploreTheme}`),
          buttonText: t('ui.io.explore.themes.hero-button'),
          buttonIcon: <SvgArrowLeft1Regular strokeWidth={3} />,
          buttonOnClick: () =>
            navigate(
              {
                pathname: '..',
                search: searchParams.toString(),
              },
              { relative: 'path' },
            ),
        }}
      >
        <Helmet title={t('ui.io.explore.themes.header-title')} />
        <Grid container justifyContent="space-between">
          <Grid
            item
            xs={12}
            sx={(theme) => ({
              maxWidth: `${theme.spacing(90)} !important`,
            })}
          >
            <Typography color="textSecondary">
              {t(`ui.io.explore.themes.intro-${exploreTheme}`)}
            </Typography>
            {themeDataExists && (
              <Box
                sx={(theme) => ({
                  margin: `${theme.spacing(3)} 0 ${theme.spacing(5)} 0`,
                  [theme.breakpoints.up('md')]: {
                    margin: `${theme.spacing(3)} 0 ${theme.spacing(7)} 0`,
                  },
                })}
                data-testid={`play-video-${exploreTheme}`}
              >
                <WistiaVideoPlayerButton
                  key={i18n.language}
                  videoId={ThemeData[exploreThemeIndex].videoId}
                  wrapperId="wistia-player-container-1"
                  buttonText={t(
                    `ui.io.explore.themes.intro-play-video-${exploreTheme}`,
                  )}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  captionsLanguage={captionLanguage}
                  setCaptionsToChosenLanguage
                />
              </Box>
            )}
            <Box sx={(theme) => ({ marginBottom: theme.spacing(1.75) })}>
              <TextV2 variant="h3">
                {t(`ui.io.explore.themes.subtitle-${exploreTheme}`)}
              </TextV2>
            </Box>
            <Typography
              color="textSecondary"
              sx={(theme) => ({
                margin: `0 0 ${theme.spacing(spacingSizeMap.S)} 0`,
                [theme.breakpoints.up('md')]: {
                  margin: `0 0 ${theme.spacing(spacingSizeMap.M)} 0`,
                },
              })}
            >
              {t('ui.io.explore.themes.jumpnav-paragraph')}
            </Typography>
          </Grid>
        </Grid>
        {jumpToPages[exploreThemeKey] && (
          <JumpTo
            pageSections={jumpToPages[exploreThemeKey]}
            title={t(
              'ui.io.explore.evaluator.digital_profile.jumpto_nav.title',
            )}
          />
        )}
        {jumpToPages[exploreThemeKey] && themeDataExists && (
          <>
            <Box id={`#${anchorId1?.anchorId}`}>
              <Skills
                scores={profile.scores.conscious}
                pageTheme={exploreTheme}
                skill="1"
              />
            </Box>
            <Box id={`#${anchorId2?.anchorId}`}>
              <Skills
                scores={profile.scores.conscious}
                pageTheme={exploreTheme}
                skill="2"
              />
            </Box>
            <Box id={`#${anchorId3?.anchorId}`}>
              <Skills
                scores={profile.scores.conscious}
                pageTheme={exploreTheme}
                skill="3"
              />
            </Box>
            <Box id={`#${anchorId4?.anchorId}`}>
              <Skills
                scores={profile.scores.conscious}
                pageTheme={exploreTheme}
                skill="4"
              />
            </Box>
            <Box id={`#${anchorId5?.anchorId}`}>
              <Skills
                scores={profile.scores.conscious}
                pageTheme={exploreTheme}
                skill="5"
              />
            </Box>
            <Box id={`#${anchorId6?.anchorId}`}>
              <Skills
                last
                scores={profile.scores.conscious}
                pageTheme={exploreTheme}
                skill="6"
              />
            </Box>
          </>
        )}
      </ProfileLayout>
    );
  }
  return (
    <FullScreenError
      message={t('ui.io.explore.evaluator.digital_profile.error')}
    />
  );
};

export default ExploreTheme;
