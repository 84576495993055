import { ButtonV2, TextV2 } from '@insights-ltd/design-library/components';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import { Box, Container } from '@mui/material';
import React from 'react';
import useAboveMobile from 'components/hooks/useAboveMobile';

export type HeroProps = {
  buttonIcon?: any;
  buttonOnClick?: any;
  buttonText?: string;
  image?: string;
  title?: string;
};

const Hero = ({
  image,
  title,
  buttonText,
  buttonIcon,
  buttonOnClick,
}: HeroProps) => {
  const aboveMobile = useAboveMobile();

  return (
    <>
      <Box
        sx={(theme) => ({
          background: image ? `url(${image})` : undefined,
          backgroundPosition: 'right center',
          backgroundSize: 'cover',
          height: aboveMobile ? '350px' : '200px',
          overflow: 'hidden',
          position: 'relative',
          width: 'auto',
          zIndex: '0',
          '&:after': {
            background: `linear-gradient(90deg, ${theme.palette.grey[800]} 0%, rgba(0, 0, 0, 0.00) 100%)`,
            content: '""',
            height: '100%',
            left: 0,
            opacity: '0.5',
            position: 'absolute',
            top: 0,
            width: '100%',
            zIndex: '1',
          },
        })}
      >
        <Container
          maxWidth="lg"
          sx={(theme) => ({
            alignItems: 'flex-start',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: aboveMobile ? 'center' : 'flex-end',
            paddingLeft: `${theme.spacing(spacingSizeMap.S)} !important`,
            paddingRight: `${theme.spacing(spacingSizeMap.S)} !important`,
            [theme.breakpoints.up(1172)]: {
              paddingLeft: '0 !important',
              paddingRight: '0 !important',
            },
          })}
          translate="no"
        >
          <Box
            sx={{
              alignItems: 'left',
              display: 'flex',
              flexDirection: 'column',
              marginLeft: 'auto',
              marginRight: 'auto',
              maxWidth: '1440px',
              width: '100%',
              zIndex: '2',
            }}
          >
            {buttonText && buttonOnClick && (
              <Box mb={2}>
                <ButtonV2
                  disableRipple
                  buttonColour="buttonDark"
                  buttonIcon={buttonIcon}
                  buttonText={buttonText}
                  onClick={buttonOnClick}
                />
              </Box>
            )}
            {title && aboveMobile && (
              <Box
                sx={(theme) => ({
                  '& > span': {
                    color: `${theme.palette.grey[100]} !important`,
                  },
                })}
              >
                <TextV2 variant="h1">{title}</TextV2>
              </Box>
            )}
          </Box>
        </Container>
      </Box>
      {title && !aboveMobile && (
        <Container
          maxWidth="lg"
          sx={(theme) => ({
            margin: `${theme.spacing(spacingSizeMap.S)} 0 ${theme.spacing(
              spacingSizeMap.S,
            )} 0`,
            paddingLeft: `${theme.spacing(spacingSizeMap.S)} !important`,
          })}
          translate="no"
        >
          <TextV2 variant="h1">{title}</TextV2>
        </Container>
      )}
    </>
  );
};

export default Hero;
