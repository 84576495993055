import { Box } from '@mui/material';
import { FullScreenError } from 'components/FullScreen';
import { ConfirmEmail, Evaluator } from 'pages';
import DigitalProfile from 'pages/DigitalProfile';
import ExploreTheme from 'pages/DigitalProfile/Themes';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useGATrackPage } from './useGATrackPage';

export const ErrorRoute = () => {
  const { t } = useTranslation();
  return <FullScreenError message={t('ui.io.invite.error.generic_error')} />;
};

const TokenError = () => {
  const { t } = useTranslation();
  return (
    <FullScreenError
      heading={t('ui.io.error.invite_expired.title')}
      message={t('ui.io.error.invite_expired.message')}
    />
  );
};

const RedirectFromOldUrl = () => {
  const { pathname } = window.location;
  const dynamicPart = pathname.replace('/exploredigitalProfile', '/profile');
  return <Navigate to={dynamicPart} replace />;
};

const ApplicationRoutes: React.FC = () => {
  useGATrackPage();
  const profileUrlPath = '/profile/:profileId/:apiToken';
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        color: theme.palette.grey[800],
        backgroundColor: theme.palette.grey[100],
      })}
    >
      <Routes>
        <Route path="/" element={<Navigate to="/error" />} />
        <Route path="/error" element={<ErrorRoute />} />
        <Route path="/tokenerror" element={<TokenError />} />
        <Route path="/invitation/*" element={<Evaluator />} />
        <Route path="/evaluators/:evaluatorLinkId" element={<ConfirmEmail />} />
        <Route
          path="/exploredigitalProfile/*"
          element={<RedirectFromOldUrl />}
        />
        <Route path={profileUrlPath} element={<DigitalProfile />} />
        <Route path={`${profileUrlPath}/learn`} element={<ExploreTheme />} />
        <Route
          path={`${profileUrlPath}/remote-working`}
          element={<ExploreTheme />}
        />
        <Route path={`${profileUrlPath}/service`} element={<ExploreTheme />} />
        <Route
          path={`${profileUrlPath}/wellbeing`}
          element={<ExploreTheme />}
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Box>
  );
};

export default ApplicationRoutes;
