import {
  ColourProfileIcon,
  TextV2,
} from '@insights-ltd/design-library/components';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import {
  Box,
  Tabs,
  Tab,
  Typography,
  styled,
  TabScrollButton,
  Grid,
  Theme,
} from '@mui/material';
import useAboveMobile from 'components/hooks/useAboveMobile';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Colour, ColourScoreWithPreference } from 'types/constants';
import { colourMapInverted } from 'variables';

type SkillsProps = {
  last?: boolean;
  pageTheme: string;
  scores: ColourScoreWithPreference;
  skill: string;
};

type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  sx: object;
  value: number;
};

type TabGroupProps = {
  colourOrder: Colour[];
  pageTheme: string;
  skill: string;
};

const MyTabScrollButton = styled(TabScrollButton)({
  backgroundColor: 'linear-gradient(to right bottom, #430089, #82ffa1)',
  marginBottom: '15px',
  overflow: 'hidden',
  transition: 'width 0.5s',
  width: '50',
  '&:first-of-type': {
    background: 'linear-gradient(to right, white 70%, transparent)',
    justifyContent: 'start',
    marginRight: '-40px',
    zIndex: 1,
  },
  '&:last-child': {
    background: 'linear-gradient(to right, transparent, white 30%)',
    justifyContent: 'end',
    marginLeft: '-40px',
  },
  '& .MuiSvgIcon-root': {
    fontSize: 'x-large',
  },
});

const TabPanel = ({ children, index, value, sx, ...other }: TabPanelProps) => {
  return (
    <div
      aria-labelledby={`tab-${index}`}
      hidden={value !== index}
      id={`tabpanel-${index}`}
      role="tabpanel"
      {...other}
    >
      {value === index && (
        <Box sx={sx}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const TabGroup = ({ colourOrder, pageTheme, skill }: TabGroupProps) => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState(0);
  const aboveMobile = useAboveMobile();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const orientation = aboveMobile ? 'vertical' : 'horizontal';

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: aboveMobile ? 'row' : 'column',
        flexGrow: 1,
        marginTop: theme.spacing(spacingSizeMap.L),
        width: '100%',
      })}
    >
      <Box>
        <Box
          sx={(theme) => ({
            display: 'inline-block',
            marginBottom: theme.spacing(2.5),
          })}
        >
          <TextV2 variant="h4">
            {t(
              `ui.io.explore.themes.skills.${pageTheme}-tabs_title-skill-${skill}`,
            )}
          </TextV2>
        </Box>
        <Tabs
          allowScrollButtonsMobile
          aria-label={`ui.io.explore.themes.skills.tab_${orientation}`}
          onChange={handleChange}
          orientation={orientation}
          ScrollButtonComponent={MyTabScrollButton}
          scrollButtons
          sx={(theme) => ({
            minWidth: theme.spacing(28),
            padding: 0,
            '.Mui-selected': {
              color: `${theme.palette.grey[700]} !important`,
            },
            [theme.breakpoints.up('md')]: {
              marginRight: theme.spacing(24.5),
            },
          })}
          TabIndicatorProps={{
            sx: (theme) => ({
              backgroundColor: theme.palette.grey[700],
              [theme.breakpoints.up('md')]: {
                left: 0,
                height: theme.spacing(5),
                marginRight: theme.spacing(spacingSizeMap.S),
                width: theme.spacing(0.5),
              },
            }),
          }}
          value={value}
          variant={!aboveMobile ? 'scrollable' : 'standard'}
        >
          {colourOrder.map((colour, index) => {
            return (
              <Tab
                aria-controls={`${orientation}-tabpanel-${index}`}
                icon={
                  <Box
                    sx={(theme) => ({
                      marginLeft: theme.spacing(2.5),
                    })}
                  >
                    <ColourProfileIcon
                      colourEnergy={colourMapInverted[colour]}
                      whiteBackgroundBorder
                      iconSize={40}
                    />
                  </Box>
                }
                iconPosition="start"
                id={`tab-${index}`}
                label={t(
                  `ui.io.explore.themes.skills.${colourMapInverted[colour]}`,
                )}
                key={colour}
                sx={(theme) => ({
                  color: theme.palette.blue.main,
                  justifyContent: 'start',
                  marginBottom: aboveMobile ? '24px' : '16px',
                  minHeight: theme.spacing(5),
                  minWidth: aboveMobile
                    ? theme.spacing(28)
                    : theme.spacing(18.5),
                  padding: 0,
                })}
              />
            );
          })}
        </Tabs>
      </Box>
      <Box>
        {colourOrder.map((colour, index) => {
          return (
            <TabPanel
              index={index}
              key={colour}
              sx={(theme: Theme) => ({
                display: 'flex',
                maxWidth: theme.spacing(90),
                [theme.breakpoints.down('md')]: {
                  marginTop: theme.spacing(spacingSizeMap.M),
                },
              })}
              value={value}
            >
              <TextV2 component="p">
                <strong>{t('ui.io.explore.themes.skills.tab_strength')}</strong>
              </TextV2>
              <Box
                sx={(theme) => ({
                  marginBottom: theme.spacing(1.75),
                  '& ul': {
                    paddingLeft: `${theme.spacing(
                      spacingSizeMap.M,
                    )} !important`,
                  },
                })}
              >
                <TextV2 component="span" color="textSecondary">
                  <ul>
                    <li>
                      {t(
                        `ui.io.explore.themes.skills.${pageTheme}-${colour}_tab_strength_1-skill-${skill}`,
                      )}
                    </li>
                    <li>
                      {t(
                        `ui.io.explore.themes.skills.${pageTheme}-${colour}_tab_strength_2-skill-${skill}`,
                      )}
                    </li>
                  </ul>
                </TextV2>
              </Box>
              <TextV2 component="p">
                <strong>{t('ui.io.explore.themes.skills.tab_stretch')}</strong>
              </TextV2>
              <Box
                sx={(theme) => ({
                  marginBottom: theme.spacing(1.75),
                  '& ul': {
                    paddingLeft: `${theme.spacing(
                      spacingSizeMap.M,
                    )} !important`,
                  },
                })}
              >
                <TextV2 component="span" color="textSecondary">
                  <ul>
                    <li>
                      {t(
                        `ui.io.explore.themes.skills.${pageTheme}-${colour}_tab_stretch_1-skill-${skill}`,
                      )}
                    </li>
                    <li>
                      {t(
                        `ui.io.explore.themes.skills.${pageTheme}-${colour}_tab_stretch_2-skill-${skill}`,
                      )}
                    </li>
                  </ul>
                </TextV2>
              </Box>
            </TabPanel>
          );
        })}
      </Box>
    </Box>
  );
};

// These commented out lines are to be utilized when we get to ticket NP-7336
const Skills = ({ scores, pageTheme, skill, last = false }: SkillsProps) => {
  const { t } = useTranslation();

  const { preferred, ...actualScores } = scores;
  const colourOrder = Object.entries(actualScores)
    .sort((a, b) => b[1] - a[1])
    .map((item) => item[0]) as Colour[];

  const skillsParagraph1 = [
    { pageTheme: 'remote-working', skill: '1' },
    { pageTheme: 'remote-working', skill: '4' },
  ].some((x) => x.pageTheme === pageTheme && x.skill === skill);

  const skillsParagraph2 = [
    { pageTheme: 'learn', skill: '4' },
    { pageTheme: 'learn', skill: '5' },
    { pageTheme: 'service', skill: '5' },
    { pageTheme: 'service', skill: '6' },
    { pageTheme: 'wellbeing', skill: '4' },
  ].some((x) => x.pageTheme === pageTheme && x.skill === skill);

  const skillsParagraph3 = [
    { pageTheme: 'learn', skill: '1' },
    { pageTheme: 'learn', skill: '2' },
    { pageTheme: 'learn', skill: '3' },
    { pageTheme: 'learn', skill: '6' },
    { pageTheme: 'remote-working', skill: '2' },
    { pageTheme: 'remote-working', skill: '3' },
    { pageTheme: 'service', skill: '1' },
    { pageTheme: 'service', skill: '2' },
    { pageTheme: 'service', skill: '3' },
    { pageTheme: 'service', skill: '4' },
    { pageTheme: 'wellbeing', skill: '2' },
    { pageTheme: 'wellbeing', skill: '3' },
    { pageTheme: 'wellbeing', skill: '6' },
  ].some((x) => x.pageTheme === pageTheme && x.skill === skill);

  const skillsParagraph4 = [
    { pageTheme: 'remote-working', skill: '6' },
    { pageTheme: 'wellbeing', skill: '5' },
  ].some((x) => x.pageTheme === pageTheme && x.skill === skill);

  const skillsParagraph5 = [{ pageTheme: 'remote-working', skill: '5' }].some(
    (x) => x.pageTheme === pageTheme && x.skill === skill,
  );

  const skillsParagraph6 = [{ pageTheme: 'wellbeing', skill: '1' }].some(
    (x) => x.pageTheme === pageTheme && x.skill === skill,
  );

  return (
    <Grid
      container
      justifyContent="space-between"
      sx={(theme) => ({
        borderBottom: last ? 0 : `1px solid ${theme.palette.grey[500]}`,
        marginTop: theme.spacing(5),
        paddingBottom: theme.spacing(last ? 15 : 5),
        [theme.breakpoints.up('md')]: {
          marginTop: theme.spacing(7),
          paddingBottom: theme.spacing(last ? 15 : 7),
        },
      })}
    >
      <Grid
        item
        xs={12}
        sx={(theme) => ({
          maxWidth: `${theme.spacing(90)} !important`,
        })}
      >
        <Box sx={(theme) => ({ marginBottom: theme.spacing(1.75) })}>
          <TextV2 variant="h3">
            {t(`ui.io.explore.themes.skills.${pageTheme}-title-skill-${skill}`)}
          </TextV2>
        </Box>
        {skillsParagraph1 && (
          <Typography color="textSecondary">
            {t(
              `ui.io.explore.themes.skills.${pageTheme}-paragraph-1-skill-${skill}`,
            )}
          </Typography>
        )}
        {skillsParagraph2 && (
          <>
            <Typography
              color="textSecondary"
              sx={(theme) => ({ marginBottom: theme.spacing(1.75) })}
            >
              {t(
                `ui.io.explore.themes.skills.${pageTheme}-paragraph-1-skill-${skill}`,
              )}
            </Typography>
            <Typography color="textSecondary">
              {t(
                `ui.io.explore.themes.skills.${pageTheme}-paragraph-2-skill-${skill}`,
              )}
            </Typography>
          </>
        )}
        {skillsParagraph3 && (
          <>
            <Typography
              color="textSecondary"
              sx={(theme) => ({ marginBottom: theme.spacing(1.75) })}
            >
              {t(
                `ui.io.explore.themes.skills.${pageTheme}-paragraph-1-skill-${skill}`,
              )}
            </Typography>
            <Typography
              color="textSecondary"
              sx={(theme) => ({ marginBottom: theme.spacing(1.75) })}
            >
              {t(
                `ui.io.explore.themes.skills.${pageTheme}-paragraph-2-skill-${skill}`,
              )}
            </Typography>
            <Typography color="textSecondary">
              {t(
                `ui.io.explore.themes.skills.${pageTheme}-paragraph-3-skill-${skill}`,
              )}
            </Typography>
          </>
        )}
        {skillsParagraph4 && (
          <>
            <Typography
              color="textSecondary"
              sx={(theme) => ({ marginBottom: theme.spacing(1.75) })}
            >
              {t(
                `ui.io.explore.themes.skills.${pageTheme}-paragraph-1-skill-${skill}`,
              )}
            </Typography>
            <Typography
              color="textSecondary"
              sx={(theme) => ({ marginBottom: theme.spacing(1.75) })}
            >
              {t(
                `ui.io.explore.themes.skills.${pageTheme}-paragraph-2-skill-${skill}`,
              )}
            </Typography>
            <Typography
              color="textSecondary"
              sx={(theme) => ({ marginBottom: theme.spacing(1.75) })}
            >
              {t(
                `ui.io.explore.themes.skills.${pageTheme}-paragraph-3-skill-${skill}`,
              )}
            </Typography>
            <Typography color="textSecondary">
              {t(
                `ui.io.explore.themes.skills.${pageTheme}-paragraph-4-skill-${skill}`,
              )}
            </Typography>
          </>
        )}
        {skillsParagraph5 && (
          <>
            <Typography
              color="textSecondary"
              sx={(theme) => ({ marginBottom: theme.spacing(1.75) })}
            >
              {t(
                `ui.io.explore.themes.skills.${pageTheme}-paragraph-1-skill-${skill}`,
              )}
            </Typography>
            <Box
              sx={(theme) => ({
                marginBottom: theme.spacing(1.75),
                '& ul': {
                  paddingLeft: `${theme.spacing(spacingSizeMap.M)} !important`,
                },
              })}
            >
              <TextV2 component="span" color="textSecondary">
                <ul>
                  <li>
                    {t(
                      `ui.io.explore.themes.skills.${pageTheme}-paragraph-1-bullet-1-skill-${skill}`,
                    )}
                  </li>
                  <li>
                    {t(
                      `ui.io.explore.themes.skills.${pageTheme}-paragraph-1-bullet-2-skill-${skill}`,
                    )}
                  </li>
                </ul>
              </TextV2>
            </Box>
            <Typography color="textSecondary">
              {t(
                `ui.io.explore.themes.skills.${pageTheme}-paragraph-2-skill-${skill}`,
              )}
            </Typography>
          </>
        )}
        {skillsParagraph6 && (
          <>
            <Typography
              color="textSecondary"
              sx={(theme) => ({ marginBottom: theme.spacing(1.75) })}
            >
              {t(
                `ui.io.explore.themes.skills.${pageTheme}-paragraph-1-skill-${skill}`,
              )}
            </Typography>
            <Typography
              color="textSecondary"
              sx={(theme) => ({ marginBottom: theme.spacing(1.75) })}
            >
              {t(
                `ui.io.explore.themes.skills.${pageTheme}-paragraph-2-skill-${skill}`,
              )}
            </Typography>
            <Box
              sx={(theme) => ({
                marginBottom: theme.spacing(1.75),
                '& ul': {
                  paddingLeft: `${theme.spacing(spacingSizeMap.M)} !important`,
                },
              })}
            >
              <TextV2 component="span" color="textSecondary">
                <ul>
                  <li>
                    {t(
                      `ui.io.explore.themes.skills.${pageTheme}-paragraph-2-bullet-1-skill-${skill}`,
                    )}
                  </li>
                  <li>
                    {t(
                      `ui.io.explore.themes.skills.${pageTheme}-paragraph-2-bullet-2-skill-${skill}`,
                    )}
                  </li>
                  <li>
                    {t(
                      `ui.io.explore.themes.skills.${pageTheme}-paragraph-2-bullet-3-skill-${skill}`,
                    )}
                  </li>
                </ul>
              </TextV2>
            </Box>
            <Typography color="textSecondary">
              {t(
                `ui.io.explore.themes.skills.${pageTheme}-paragraph-3-skill-${skill}`,
              )}
            </Typography>
          </>
        )}
      </Grid>
      <TabGroup colourOrder={colourOrder} pageTheme={pageTheme} skill={skill} />
    </Grid>
  );
};

export default Skills;
